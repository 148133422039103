<template>
  <b-container>
    <b-row v-if="!permissions.view">
      <b-col>
        No permissions
      </b-col>
    </b-row>
    <b-row v-if="permissions.view">
      <b-container class="bg-white">
        <b-row v-if="show.table">
          <b-col>
            <h1 class="text-dark kstyle mb-4">Post-trade transparency scenarios</h1>
            <div v-for="row in config" :key="row.key">
              <b-form-group v-if="row.options" :label-for="row.key" :label="row.label" label-cols-lg="2" content-cols-lg="8" label-align-lg="right">
                <b-form-select v-model="$data[row.selected]" :options="$data[row.options]" :id="row.key"></b-form-select>
              </b-form-group>
            </div>
            <b-form-group label-for="search" label="search" label-cols-lg="2" content-cols-lg="8" label-align-lg="right">
              <b-form-input v-model="filter" id="search" placeholder="type to search" />
            </b-form-group>
            <b-form-group label-cols-lg="2" content-cols-lg="8">
              <b-btn class="mr-2" @click="clearAll">clear all</b-btn>
              <b-btn v-b-toggle.columnsSidebar>manage columns</b-btn>
              <b-sidebar id="columnsSidebar" title="Columns" shadow>
                <div class="p-4">
                  <b-form-checkbox-group
                    id="checkbox-columns"
                    v-model="columnsSelected"
                    :options="columnsOptions"
                    name="flavour-1"
                    stacked
                  ></b-form-checkbox-group>
                </div>
              </b-sidebar>
            </b-form-group>
            <b-table style="font-size: 12px" striped hover :items="dataFiltered" :fields="fields" :filter="filter" @row-clicked="showScenario"></b-table>
          </b-col>
        </b-row>
        <b-row v-if="show.scenario">
          <b-col>
            <b-row class="mb-3">
              <b-col><h1 class="text-dark kstyle mb-4"> Post-trade scenario {{ scenario.id }}</h1></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-row>
                  <ul>
                    <li><strong>{{ scenario.scenario_family }}</strong></li>
                    <li><strong>{{ scenario.scenario_description }}</strong></li>
                  </ul>
                </b-row>
                <b-row>
                  <b-col><strong>&nbsp;</strong></b-col>
                  <b-col><strong>Buyer</strong></b-col>
                  <b-col><strong>Seller</strong></b-col>
                </b-row>
                <b-row>
                  <b-col>Type</b-col>
                  <b-col>{{ scenario.buyer }}</b-col>
                  <b-col>{{ scenario.seller }}</b-col>
                </b-row>
                <b-row>
                  <b-col>Trading capacity</b-col>
                  <b-col>{{ scenario.buyer_trading_capacity }}</b-col>
                  <b-col>{{ scenario.seller_trading_capacity }}</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="5">Product</b-col>
                  <b-col>{{ scenario.product }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">ToTV</b-col>
                  <b-col>{{ scenario.totv }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Lifecylce event</b-col>
                  <b-col>{{ scenario.lifecycle_event }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Execution type</b-col>
                  <b-col>{{ scenario.execution_type }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Deferral</b-col>
                  <b-col>{{ scenario.deferral }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Post-trade flags</b-col>
                  <b-col>{{ scenario.post_trade_flags }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">PTT outcome</b-col>
                  <b-col>{{ scenario.ptt_outcome }}</b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-row>
                  <b-col><h3>Eligibility details</h3></b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Equity / non-equity</b-col>
                  <b-col>{{ scenario.equity_nonequity }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Instrumnet in-scope for PTT</b-col>
                  <b-col>{{ scenario.instrument_inscope }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Instrument ToTV</b-col>
                  <b-col>{{ scenario.instrument_totv }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">PPT reportable lifecycle event</b-col>
                  <b-col>{{ scenario.ptt_reportable_lifecycle_event }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Eligible for PTT</b-col>
                  <b-col>{{ scenario.eligible_for_ptt }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Eligible for NQU / EQU</b-col>
                  <b-col>{{ scenario.eligible_for_nqu_equ }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Eligibility reasoning</b-col>
                  <b-col>{{ scenario.eligibility_reasoning }}</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col><h3>Transaction details</h3></b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Execution time</b-col>
                  <b-col>{{ scenario.execution_time }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Buyer</b-col>
                  <b-col>{{ scenario.buyer_type }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Seller</b-col>
                  <b-col>{{ scenario.seller_type }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Submitting trading capacity</b-col>
                  <b-col>{{ scenario.submitting_trading_capacity }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Buyer trading capacity</b-col>
                  <b-col>{{ scenario.transaction_buyer_trading_capacity }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Seller trading capacity</b-col>
                  <b-col>{{ scenario.transaction_seller_trading_capacity }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Venue</b-col>
                  <b-col>{{ scenario.venue }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Product</b-col>
                  <b-col>{{ scenario.transaction_product }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">Part of package</b-col>
                  <b-col>{{ scenario.part_of_package }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="5">PPT workflow lifecycle</b-col>
                  <b-col>{{ scenario.ppt_workflow_lifecycle }}</b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-row>
                  <b-col><h3>Report details</h3></b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">Eligible for deferrals</b-col>
                  <b-col>{{ scenario.eligible_for_deferrals }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">Deferral reasoning</b-col>
                  <b-col>{{ scenario.deferral_reasoning }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">Enhanced deferral reasoning</b-col>
                  <b-col>{{ scenario.enhanced_deferral_reasoning }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">Post-trade flags</b-col>
                  <b-col>{{ scenario.report_post_trade_flags }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">Expected outcome</b-col>
                  <b-col>{{ scenario.expected_outcome }}</b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col><b-btn @click="showTable">show all</b-btn></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash"
import ac from "../libs/accesscontrol"
import data from "../libs/ptt"

function buildConfig () {
  const data = [
    { key: "id", sortable: true, field: true },
    { key: "scenario_family", sortable: true, label: "scenario family", options: true },
    { key: "scenario_description", sortable: true, field: true },
    // { key: "firm_type", sortable: true, label: "firm type", options: true, field: true },
    // { key: "product", sortable: true, label: "product", field: true },
    { key: "totv", sortable: true },
    { key: "buyer", sortable: true, label: "buyer", options: true, field: true },
    { key: "seller", sortable: true, label: "seller", options: true, field: true },
    { key: "lifecycle_event", sortable: true, label: "lifecycle event", options: true, field: true, removableColumn: true },
    { key: "execution_type", sortable: true, label: "execution type", options: true, field: true, removableColumn: true },
    { key: "submitting_entity_trading_capacity", label: "submitting entity trading capacity", sortable: true, field: false, removableColumn: true },
    { key: "buyer_trading_capacity", label: "buyer trading capacity", sortable: true, field: false, removableColumn: true },
    { key: "seller_trading_capacity", label: "seller trading capacity", sortable: true, field: false, removableColumn: true },
    { key: "deferral", label: "deferral", sortable: true, field: true, removableColumn: true },
    { key: "enhanced_deferral", label: "enhanced deferral", sortable: true, field: true, removableColumn: true },
    { key: "post_trade_flags", label: "post-trade flags", sortable: true, field: true, removableColumn: true },
    { key: "expected_outcome", label: "expected outcome", sortable: true, field: true, removableColumn: true }
  ]
  _.each(data, (row) => {
    if (row.options) {
      row.selected = row.key + "Selected"
      row.options = row.key + "Options"
    }
  })
  return data
}

const config = buildConfig()

function buildFields () {
  const fields = []
  _.each(config, (row) => {
    if (row.field) {
      fields.push(row)
    }
  })
  return fields
}

function buildColumnsOptions () {
  const options = []
  _.each(config, (row) => {
    if (row.removableColumn) {
      options.push({ text: row.label, value: row.key })
    }
  })
  return options
}

export default {
  name: "Ptt",
  components: {},
  computed: {
    dataFiltered: function () {
      let data = this.data
      // filter data for each option:true field
      _.each(config, (row) => {
        if (row.options) {
          if (this[row.selected]) {
            data = _.filter(data, (d) => {
              return d[row.key] === this[row.selected]
            })
          }
        }
      })
      return data
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'PTT', action: 'open PTT' })
    this.permissions.view = ac.can(this.user.acgroups).readAny("nfrsection").granted
    this.data = data
    _.each(data[0], (value, key) => {
      this.dataArrays[key] = []
    })
    _.each(data, (row) => {
      _.each(row, (value, key) => {
        this.dataArrays[key].push(value)
      })
    })
    this.dataArraysUniq = _.mapValues(this.dataArrays, (row) => {
      return _.uniq(row).sort()
    })
    // build options for drop-downs
    _.each(config, (row) => {
      if (row.options) {
        _.each(this.dataArraysUniq[row.key], (v) => {
          this[row.options].push({ value: v, text: v })
        })
      }
    })
    // identify which columns are selected
    _.each(this.columnsOptions, option => {
      if (_.find(this.fields, ['key', option.value])) {
        this.columnsSelected.push(option.value)
      }
    })
  },
  data () {
    const data = {
      config: buildConfig(),
      columnsOptions: buildColumnsOptions(),
      columnsSelected: [],
      data: [],
      dataArrays: {},
      dataArraysUniq: {},
      fields: buildFields(),
      filter: '',
      permissions: {
        view: false
      },
      scenario: {},
      show: {
        scenario: false,
        table: true
      }
    }
    // build data fields for selected and options
    _.each(config, (row) => {
      if (row.options) {
        data[row.selected] = null
        data[row.options] = [{ value: null, text: "select " + row.label }]
      }
    })
    return data
  },
  methods: {
    clearAll: function () {
      this.filter = ''
      _.each(config, (row) => {
        if (row.options) {
          this.$data[row.selected] = null
        }
      })
    },
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    showScenario: function (scenario) {
      this.scenario = scenario
      this.clearShow()
      this.show.scenario = true
    },
    showTable: function () {
      this.clearShow()
      this.show.table = true
    }
  },
  watch: {
    columnsSelected: function () {
      _.each(this.columnsOptions, option => {
        if (this.columnsSelected.includes(option.value)) {
          if (!_.find(this.fields, ['key', option.value])) {
            this.fields.push(_.find(config, ['key', option.value]))
          }
        } else {
          if (_.find(this.fields, ['key', option.value])) {
            _.remove(this.fields, ['key', option.value])
            this.fields.push('1')
            this.fields.pop()
          }
        }
      })
    }
  }
}
</script>

<style>
</style>
