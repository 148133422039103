export default [
  {
    "id": 1.1,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "Both parties EU entities",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 1.2,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "1 entity is a non-EU entity",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 1.3,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "Both parties EU entities but product is equity",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 1.4,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "Both parties EU entities but product is not TOTV",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "No",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Not TOTV",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 1.5,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "Trade is deferred due to ILQD",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 1.6,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "EU Investment firm executes with a member of the European System of Central Banks in performance of its monetary policy",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Spot",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "ESCB",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "ESCB",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Spot",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Entities: ESCB\nInstrument: FX Spot not in-scope for PTT reporting",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 1.7,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "EU Investment firm executes with a member of the European System of Central Banks to hold or manage official foreign reserves",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "ESCB",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "ESCB",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Entities: ESCB",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 1.8,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "EU Investment firm executes with a member of the European System of Central Banks for the purpose of financial stability policy",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "ESCB",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "ESCB",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Entities: ESCB",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 1.9,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "EU Investment firm executes with a member of the European System of Central Banks that is not in performance of its monetary policy",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "Yes",
    "buyer": "ESCB",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "ESCB",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 1.1,
    "scenario_family": "Voice (RFQ) - EU investment firm trading with an investment firm (EU and Non-EU) over Voice",
    "scenario_description": "EU Investment firm executes a placeholder trade on voice with a EU client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 2.1,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.2,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.3,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "An SI trades 2 times in the same instrument with an EU Investment Firm over a week via an MTF and the report is deferred and aggregated",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "ILQD",
    "enhanced_deferral": "VOLW",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "VOLW",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.4,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "As part of a package an SI trades with an SI via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "Yes",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.5,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with a Non-EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETF",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "ETF",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.6,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF which benefits from a deferral, the trade is then cancelled before report is published",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Swap",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "IRS Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Trade cancelled prior to report published",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 2.7,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with a Non-EU Investment Firm via an MTF, the trade is then cancelled",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "CANC",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.8,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Spot",
    "totv": "No",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "FX Spot",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "N/A",
    "instrument_inscope": "No",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: FX Spot out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "No Reports"
  },
  {
    "id": 2.9,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF, the trade is cancelled before publication",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "CANC",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "No Reports"
  },
  {
    "id": 2.1,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF, the trade is cancelled after publication",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "CANC",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.11,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF, the trade is amended before publication",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 2.12,
    "scenario_family": "MTF / OTF - EU dealer with an investment firm (EU and Non-EU) over MTF / OTF",
    "scenario_description": "SI trades with an EU Investment Firm via an MTF, the trade is amended after publication",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "AMND",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "AMND",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.1,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "Non-IF trades with SI on an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.2,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "As part of a package an SI trades with SI on an OTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS & Swaption",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS & ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "OTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "OTF",
    "transaction_product": "CDS & Swaption",
    "part_of_package": "Yes",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS & ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "OTF Reports"
  },
  {
    "id": 3.3,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "Non-IF trades with an EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.4,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "Non-IF trades with an EU Investment Firm via an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.5,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "EU Investment firm executes a reference price transaction on an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.6,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "EU Investment firm executes 2 reference price transactions in the same instrument on an MTF that benefits from an enhanced deferral",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "ILQD & LRGS",
    "enhanced_deferral": "VOLW",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD & LRGS",
    "enhanced_deferral_reasoning": "VOLW",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 3.7,
    "scenario_family": "MTF / OTF (cleared) - EU dealer trading with EU investment firm over MTF / OTF",
    "scenario_description": "Non-IF trades with SI on an MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Repo",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Repo",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "No",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Repo out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "N/A",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 4.1,
    "scenario_family": "MTF / OTF (negotiated) - EU investment firm agree trade with EU investment firm over Voice and trading on Venue",
    "scenario_description": "Non-IF trades with an SI over voice which then sources the trade from an MTF with an EU Investment Firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS (MAC)",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Report &\nMTF Report",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "IRS (MAC)",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Report &\nMTF Report"
  },
  {
    "id": 4.2,
    "scenario_family": "MTF / OTF (negotiated) - EU investment firm agree trade with EU investment firm over Voice and trading on Venue",
    "scenario_description": "As part of a package a Non-IF trades with an SI over voice which then sources the trade from an MTF with an EU Investment Firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS (FLY)",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI & ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "SI Report &\nMTF Report",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "IRS (FLY)",
    "part_of_package": "Yes",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI & ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "SI Report &\nMTF Report"
  },
  {
    "id": 5.1,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an OTF in the same instrument multiple times in a week, after publication of full details flag, one transaction is amended",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "OTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "OTF Reports"
  },
  {
    "id": 5.2,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "Non-EU Investment Firm trades with an Non-EU Investment Firm on an MTF and benefits from a deferral",
    "firm_type": "Trading Venue",
    "product": "Cross-Currency Swap",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "VOLO",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "Cross-Currency Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "VOLO",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.3,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm on an MTF and benefits from a 2hr deferral",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETF",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "ETF",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.4,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm on an MTF and is deferred",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "OIS  Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "OIS  Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.5,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment firm executes with a EU Investment firm on an OTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Non-physically settled electricity derivative",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "VOLO",
    "post_trade_flags": "N/A",
    "ptt_outcome": "OTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Non-physically settled electricity derivative",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "VOLO",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "OTF Reports"
  },
  {
    "id": 5.6,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment firm executes with a EU Investment firm on an OTF in a REMIT instrument",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Physically settled gas derivative",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Physically settled gas derivative",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "No",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: REMIT out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 5.7,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU investment firm executes with an EU investment firm on an OTF but the transparency obligation has been temporarily suspended by the NCA",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission allowance",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Emission allowance",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: PTT obligation suspended",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 5.8,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an MTF in the same instrument multiple times in a week, prior to publication of aggregate report, one transaction is amended",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.9,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an MTF in the same instrument multiple times in a week, after publication of aggregate report, one transaction is amended",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.1,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an MTF in the same instrument multiple times in a week, prior to publication of aggregate report, one transaction is cancelled",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.11,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an MTF in the same instrument multiple times in a week, after publication of aggregate report, one transaction is cancelled",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "MTCH",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 5.12,
    "scenario_family": "MTF / OTF (Bilateral) - Investment firm trading with Investment firm (both EU and Non-EU) over MTF / OTF",
    "scenario_description": "EU Investment Firm trades on an MTF in the same instrument multiple times in a week, after publication of full details flag, one transaction is cancelled",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "CANC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 6.1,
    "scenario_family": "MTF / OTF (Cleared) + RM - EU client trading with EU investment firm over MTF / OTF",
    "scenario_description": "As part of a package an EU Investment Firm trades on an OTF (IRS Swap) and an RM (Commodity Future)",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Swap & Commodity Future",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF & RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "OTF & RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "OTF & RM",
    "transaction_product": "IRS Swap & Commodity Future",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "OTF & RM Reports"
  },
  {
    "id": 6.2,
    "scenario_family": "MTF / OTF (Cleared) + RM - EU client trading with EU investment firm over MTF / OTF",
    "scenario_description": "As part of a package an SI trades on an MTF (Bond) and an RM (ETN), the Bond is ILQD",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond & ETN",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF & RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "MTF & RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF & RM",
    "transaction_product": "Bond & ETN",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "MTF & RM Reports"
  },
  {
    "id": 7.1,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "A client (non-IF) trades with an EU Investment Firm that sources from an RM, the report is deferred for 1 hour",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT\nMTCH trade should be reported as single transaction",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 7.2,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "A client (non-IF) trades with an EU Investment Firm that sells onto a RM, the report is deferred for 2 hours",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT\nMTCH trade should be reported as single transaction",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 7.3,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "A client (non-IF) trades with an EU Investment Firm that sources from an RM , the report is deferred for 2 hours but cancelled in under 2 hours",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "CANC",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "No Reports"
  },
  {
    "id": 7.4,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "A client (non-IF) trades with an EU Investment Firm that sources from an RM",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT\nMTCH trade should be reported as single transaction",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 8.1,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm that sources from an RM (2 reports)",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports \nRM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports \nRM Reports"
  },
  {
    "id": 8.2,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm that sources from an RM (2 reports)",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Depository Receipt",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports \nRM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Depository Receipt",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports \nRM Reports"
  },
  {
    "id": 8.3,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm that sources from an RM (2 reports)",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports \nRM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports \nRM Reports"
  },
  {
    "id": 8.4,
    "scenario_family": "RM (client order) - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm trades with an EU Investment Firm that sources from an RM (2 reports)",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETF",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports \nRM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "ETF",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports \nRM Reports"
  },
  {
    "id": 9.1,
    "scenario_family": "Client order on MTF / OTF - EU Investment firm filling client order (market order) on MTF / OTF",
    "scenario_description": "EU Investment firm trades with an EU Investment firm on an MTF and then allocates to underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 9.2,
    "scenario_family": "Client order on MTF / OTF - EU Investment firm filling client order (market order) on MTF / OTF",
    "scenario_description": "EU Investment firm trades with an EU Investment firm on an MTF and then allocates to underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "BENC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "BENC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 9.3,
    "scenario_family": "Client order on MTF / OTF - EU Investment firm filling client order (market order) on MTF / OTF",
    "scenario_description": "SI trades with an EU Investment firm on an MTF and then allocates to underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ALGO",
    "ptt_outcome": "MTF Reports \nSI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "MTF",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ALGO",
    "expected_outcome": "MTF Reports \nSI Reports"
  },
  {
    "id": 9.4,
    "scenario_family": "Client order on MTF / OTF - EU Investment firm filling client order (market order) on MTF / OTF",
    "scenario_description": "EU Investment firm trades with an EU Investment firm on an MTF and then allocates to underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "AOTC",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF (not approved by NCA)",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "MTF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF (not approved by NCA)",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 10.1,
    "scenario_family": "Client order filled internally -  an investment firm (EU and Non-EU) filling client order (market order) internally",
    "scenario_description": "Non-IF trades with an EU Investment Firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 10.2,
    "scenario_family": "Client order filled internally -  an investment firm (EU and Non-EU) filling client order (market order) internally",
    "scenario_description": "EU IF trades with an EU Investment Firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 10.3,
    "scenario_family": "Client order filled internally -  an investment firm (EU and Non-EU) filling client order (market order) internally",
    "scenario_description": "Non-IF trades with an SI",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports"
  },
  {
    "id": 11.1,
    "scenario_family": "2 client orders -Two EU Client Orders are matched internally",
    "scenario_description": "SI interposes itself between 2 EU Investment Firms",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 11.2,
    "scenario_family": "2 client orders -Two EU Client Orders are matched internally",
    "scenario_description": "SI interposes itself between 2 non-IF",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Interposing EU firm reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Interposing EU firm reports"
  },
  {
    "id": 11.3,
    "scenario_family": "2 client orders -Two EU Client Orders are matched internally",
    "scenario_description": "SI interposes itself between 2 non-IF",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Interposing EU firm reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Interposing EU firm reports"
  },
  {
    "id": 12.1,
    "scenario_family": "Client order partially filled on RM - EU investment firm partially filling client order (market order) on RM",
    "scenario_description": "EU investment firm fills part of an order on a RM and part Off-venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports\nSI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM & XOFF",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports\nSI Reports"
  },
  {
    "id": 12.2,
    "scenario_family": "Client order partially filled on RM - EU investment firm partially filling client order (market order) on RM",
    "scenario_description": "Non-EU investment firm fills part of an order on a RM and part Off-venue",
    "firm_type": "Trading Venue",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports\nNo report",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM & XOFF",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: Executed on RM\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports\nNo report"
  },
  {
    "id": 12.3,
    "scenario_family": "Client order partially filled on RM - EU investment firm partially filling client order (market order) on RM",
    "scenario_description": "SI fills part of an order on a 3rd country equivalent venue and part Off-venue",
    "firm_type": "SI",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue & SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "3rd country venue reports & \nSI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue & SI",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: Executed on equivalent 3rd country venue\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "3rd country venue reports & \nSI Reports"
  },
  {
    "id": 13.1,
    "scenario_family": "Hitting own order - Two EU Client Orders are matched internally",
    "scenario_description": "EU Investment executes on an RM with itself for two underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 13.2,
    "scenario_family": "Hitting own order - Two EU Client Orders are matched internally",
    "scenario_description": "EU Investment executes on an RM with itself for two underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 13.3,
    "scenario_family": "Hitting own order - Two EU Client Orders are matched internally",
    "scenario_description": "Non-EU branch of an EU Investment firm executes on an RM with the EU branch of the same Investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "Non-EU branch",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.21",
    "buyer_type": "Non-EU branch",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "N/A",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 14.1,
    "scenario_family": "Several clients - one fill",
    "scenario_description": "Non-EU investment firm fills multiple client orders on a RM with 1 fill",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Government Bond",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "AOTC",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "RM",
    "transaction_product": "Government Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 14.2,
    "scenario_family": "Several clients - one fill",
    "scenario_description": "SI fills multiple client orders with 1 fill",
    "firm_type": "SI",
    "product": "Government Bond",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "RM",
    "transaction_product": "Government Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 15.1,
    "scenario_family": "Several clients - multiple fills",
    "scenario_description": "EU investment firm fills multiple client orders on a RM with 3 fills",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Convertible Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Convertible Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 15.2,
    "scenario_family": "Several clients - multiple fills",
    "scenario_description": "EU investment firm fills multiple client orders on a RM with 3 fills",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Convertible Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM",
    "transaction_product": "Convertible Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 16.1,
    "scenario_family": "Several clients - multiple fills",
    "scenario_description": "EU investment firm fills multiple client orders with 2 fills on a RM",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "2 RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "RM",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "2 RM Reports"
  },
  {
    "id": 16.2,
    "scenario_family": "Several clients - multiple fills",
    "scenario_description": "Non-EU investment firm fills multiple client orders with 2 fills on a RM",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "2 RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "RM",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "2 RM Reports"
  },
  {
    "id": 17.1,
    "scenario_family": "Extraterritoriality - Branch considerations",
    "scenario_description": "Non-EU branch of an EU Investment firm executes on a 3rd country equivalent venue",
    "firm_type": "Non- UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "Non-EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "3rd country venue reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: Branch of EU investment firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "3rd country venue reports"
  },
  {
    "id": 17.2,
    "scenario_family": "Extraterritoriality - Branch considerations",
    "scenario_description": "Non-EU branch of an EU Investment firm executes on a non-equivalent 3rd country venue (instrument is TOTV)",
    "firm_type": "Non- UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "Non-EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: Branch of EU investment firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 17.3,
    "scenario_family": "Extraterritoriality - Branch considerations",
    "scenario_description": "Non-EU branch of an EU Investment firm executes off venue",
    "firm_type": "Non- UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "Non-EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: Branch of EU investment firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 17.4,
    "scenario_family": "Extraterritoriality - Branch considerations",
    "scenario_description": "EU branch of a non-EU Investment firm executes on a non-equivalent venue",
    "firm_type": "UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: EU branch\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 17.5,
    "scenario_family": "Extraterritoriality - Branch considerations",
    "scenario_description": "EU branch of a non-EU Investment firm executes off venue",
    "firm_type": "UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "Metal Future",
    "totv": "Yes",
    "buyer": "EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Metal Future",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: EU branch\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 18.1,
    "scenario_family": "SEF - EU investment firm trading on SEF",
    "scenario_description": "EU investment firm executes a trade on an equivalent third party venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Index",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "3rd country venue reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "3rd Country Venue",
    "transaction_product": "IRS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "3rd country venue reports"
  },
  {
    "id": 18.2,
    "scenario_family": "SEF - EU investment firm trading on SEF",
    "scenario_description": "EU investment firm executes a trade on an equivalent third party venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "No",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "3rd country venue reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "3rd Country Venue",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Not TOTV",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "3rd country venue reports"
  },
  {
    "id": 19.1,
    "scenario_family": "ETP - EU investment firm trading on ETP",
    "scenario_description": "EU investment firm executes a trade on a non-equivalent third party venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 19.2,
    "scenario_family": "ETP - EU investment firm trading on ETP",
    "scenario_description": "EU investment firm executes a trade on a non-equivalent third party venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "No",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "3rd Country Venue",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Not TOTV",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 20.1,
    "scenario_family": "Inter-affiliate trade",
    "scenario_description": "Investment firm's non EU affiliate (separate LEI) trades with the EU Investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 20.2,
    "scenario_family": "Inter-affiliate trade",
    "scenario_description": "Investment firm's EU affiliate (separate LEI) trades with the EU investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 21.1,
    "scenario_family": "Intra-entity trade",
    "scenario_description": "1 desk within an EU Investment firm trades with another desk",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Intra-Entity trades between same LEI are out of scope",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 22.1,
    "scenario_family": "Client Auto b2b trade",
    "scenario_description": "Non-EU Investment firm executes a trade on an equivalent third country venue and then B2B the trades to an EU investment firm that allocates the trade to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "3rd Country Venue",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Third country venue reports & the B2B trade was executed at the same time and price as the original transaction and as such should be reported as a single trade",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 22.2,
    "scenario_family": "Client Auto b2b trade",
    "scenario_description": "Non-EU Investment firm executes a trade on a non-equivalent third country venue and then B2B the trades to an EU investment firm that allocates the trade to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "3rd Country Venue",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Venue trade was not reported and as such the B2B trade should be reported as an original trade",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 22.3,
    "scenario_family": "Client Auto b2b trade",
    "scenario_description": "Non-EU branch of an EU investment firm executes a trade on a non-equivalent third country venue and then B2B the trades to an EU investment firm that allocates the trade to an underlying client",
    "firm_type": "Non- UK/EU Branch of MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "Non-EU branch",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "3rd country venue",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU branch",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "3rd Country Venue",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Non-EU branch has obligation to report & the B2B trade was executed at the same time and price as the original transaction and as such should be reported as a single trade",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 23.1,
    "scenario_family": "Single dealer platform - EU client trading with EU investment firm over Single Dealer Platform",
    "scenario_description": "EU client trades on an EU investment firm's SDP",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 23.2,
    "scenario_family": "Single dealer platform - EU client trading with EU investment firm over Single Dealer Platform",
    "scenario_description": "Non-EU client trades on an EU investment firm's SDP",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission Allowances",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Emission Allowances",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 23.3,
    "scenario_family": "Single dealer platform - EU client trading with EU investment firm over Single Dealer Platform",
    "scenario_description": "EU client trades on an SI's SDP",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission Allowance Derivatives",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "CANC",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "SI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Emission Allowance Derivatives",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "SI Reports"
  },
  {
    "id": 24.1,
    "scenario_family": "Bloomberg single-dealer - EU client trading with EU investment firm over Bloomberg Single Dealer page",
    "scenario_description": "EU client trades on Bloomberg platform with an EU investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 24.2,
    "scenario_family": "Bloomberg single-dealer - EU client trading with EU investment firm over Bloomberg Single Dealer page",
    "scenario_description": "Non-EU client trades on Bloomberg platform with a non-EU investment firm",
    "firm_type": "Trading Venue",
    "product": "Muni Bonds",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Muni Bonds",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: No MiFID Investment Firms involved \nEvent: In-scope for PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 25.1,
    "scenario_family": "Give-up & Step-outs",
    "scenario_description": "EU Investment firm executes a trade on an OTF and then executes a gives-up with another EU investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Equity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "OTF Reports &\n No report for Give Up",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Equity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Event: Give Up / Give In are out-of-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "OTF Reports &\n No report for Give Up"
  },
  {
    "id": 25.2,
    "scenario_family": "Give-up & Step-outs",
    "scenario_description": "EU Investment firm executes a trade on an OTF and then executes a gives-up with another non-EU investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "MTCH",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "OTF Reports &\n No report for Give Up",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "OTF",
    "transaction_product": "FX Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Event: Give Up / Give In are out-of-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "OTF Reports &\n No report for Give Up"
  },
  {
    "id": 25.3,
    "scenario_family": "Give-up & Step-outs",
    "scenario_description": "Non-EU Investment firm executes a trade off-venue and then executes a gives-up with another EU investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports &\n No report for Give Up",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Event: Give Up / Give In are out-of-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports &\n No report for Give Up"
  },
  {
    "id": 25.4,
    "scenario_family": "Give-up & Step-outs",
    "scenario_description": "EU Investment firm executes a step-out",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETF",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "ETF",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 26.1,
    "scenario_family": "Transmit client order - EU investment firm transmitting client order to another firm",
    "scenario_description": "EU Investment firm executes a trade with an EU investment firm on a principal basis and then fills an underlying client order",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Depository Receipt",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Depository Receipt",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 27.1,
    "scenario_family": "Transmit client order - EU investment firm receiving transmitted client order from another firm",
    "scenario_description": "EU Investment firm executes a trade with an EU investment firm on a agency basis which then sources the trade from an RM",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM & Off-Venue",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 28.1,
    "scenario_family": "Introducing broker - EU client trading with EU investment firm via Introducing Broker",
    "scenario_description": "EU client executes a trade with an EU Investment Firm through an Introducing Broker",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "AMND",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "AMND",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 28.2,
    "scenario_family": "Introducing broker - EU client trading with EU investment firm via Introducing Broker",
    "scenario_description": "Non-EU client executes a trade with an EU Investment Firm through an Introducing Broker",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Security Swap",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "AMND",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "CDS Security Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "AMND",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 29.1,
    "scenario_family": "Co-ordinating & B&D bank - EU investment firm filling client order (market order) on RM*",
    "scenario_description": "EU investment firm fills client orders in an new Bond issuance on an RM and then allocates to the underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "No",
    "buyer": "EU Investment Firm",
    "seller": "Bond Issuer",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Bond Issuer",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Primary market not in-scope for PTT\nEvent: Allocations not in-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 29.2,
    "scenario_family": "Co-ordinating & B&D bank - EU investment firm filling client order (market order) on RM*",
    "scenario_description": "EU investment executes in an new Bond issuance and then subsequently sells to an EU client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "Bond Issuer",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Bond Issuer",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 30.1,
    "scenario_family": "Only B&D bank - EU investment firm filling client order (market order) on RM*",
    "scenario_description": "EU investment firm acting as the B&D Bank fills client orders in an new Bond issuance and then allocates to the underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "No",
    "buyer": "EU Investment Firm",
    "seller": "Co-ordinating bank",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Co-ordinating bank",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Primary market not in-scope for PTT\nEvent: Allocations not in-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 31.1,
    "scenario_family": "Only Co-ordinating bank -  EU investment firm filling client order (market order) on RM*",
    "scenario_description": "EU investment firm acting as the co-ordinating Bank fills B&D bank orders in a new Bond issuance",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "No",
    "buyer": "EU Investment Firm",
    "seller": "Co-ordinating bank",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Co-ordinating bank",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Primary market not in-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 32.1,
    "scenario_family": "Street trade - Street trade with EU counterparty",
    "scenario_description": "SI executes with another SI",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports (Seller)",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports (Seller)"
  },
  {
    "id": 32.2,
    "scenario_family": "Street trade - Street trade with EU counterparty",
    "scenario_description": "SI executes with an EU Investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports"
  },
  {
    "id": 32.3,
    "scenario_family": "Street trade - Street trade with EU counterparty",
    "scenario_description": "SI purchases a short term money market instrument",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Cash deposit",
    "totv": "No",
    "buyer": "SI",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "Cash deposit",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Not TOTV",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 33.1,
    "scenario_family": "IOI filled via venue - EU client agree trade with EU investment firm via IOI",
    "scenario_description": "EU Investment firm executes a trade with an EU Investment Firm on an MTF and then allocates to underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 33.2,
    "scenario_family": "IOI filled via venue - EU client agree trade with EU investment firm via IOI",
    "scenario_description": "EU Investment firm executes a trade with an EU Investment Firm on an MTF and then allocates to multiple underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "IRS Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 34.1,
    "scenario_family": "IOI filled internally - 2 fills - EU client agree trade with EU investment firm via IOI",
    "scenario_description": "EU Investment firm executes a trade as part of a package (one component is ILQD) with an EU Investment Firm on an MTF and then allocates to underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond & IRS",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Bond & IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 34.2,
    "scenario_family": "IOI filled internally - 2 fills - EU client agree trade with EU investment firm via IOI",
    "scenario_description": "EU Investment firm executes a trade with 2 Bond fills (one fill is SSTI) and one CDS as part of a package with an EU Investment Firm on an MTF and then allocates to underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond & CDS Security Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "TPAC",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Bond & CDS Security Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "TPAC",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 35.1,
    "scenario_family": "Issue own notes - EU investment firm issuing own notes",
    "scenario_description": "SI sells to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Structured Note",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Structured Note",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports"
  },
  {
    "id": 35.2,
    "scenario_family": "Issue own notes - EU investment firm issuing own notes",
    "scenario_description": "SI sells to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Structured Note",
    "totv": "No",
    "buyer": "Non-EU Investment Firm",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Structured Note",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "No",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Instrument: Not TOTV",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 36.1,
    "scenario_family": "Retire own issued notes - EU investment firm retiring own notes",
    "scenario_description": "SI buys from an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Structured Note",
    "totv": "Yes",
    "buyer": "SI",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "SI",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Structured Note",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports"
  },
  {
    "id": 37.1,
    "scenario_family": "Listed Derivatives below block size - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm sources an order in two fills on a RM and then fills an underlying client order in two fills",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETD",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "2 RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "ETD",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "2 RM Reports"
  },
  {
    "id": 37.2,
    "scenario_family": "Listed Derivatives below block size - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm acts as an agent for an underlying client and requires two fills to source the order from an RM",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETD",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "2 RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "ETD",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "2 RM Reports"
  },
  {
    "id": 37.3,
    "scenario_family": "Listed Derivatives below block size - EU investment firm filling client order (market order) on RM",
    "scenario_description": "EU interposes itself between an underlying client and an RM and executes twice",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETD",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "MTCH",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "2 RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "MTCH",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM",
    "transaction_product": "ETD",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "2 RM Reports"
  },
  {
    "id": 38.1,
    "scenario_family": "Listed Derivatives above block size - Investment firm (both EU and Non-EU) filling client order (market order) on RM",
    "scenario_description": "EU Investment Firm negotiates with another EU Investment firm which then reported on an RM and then sells to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETD",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "SSTI",
    "enhanced_deferral": "VOLO (not approved by NCA)",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM Reports & \nSeller Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "RM & XOFF",
    "transaction_product": "ETD",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "SSTI",
    "enhanced_deferral_reasoning": "VOLO (not approved by NCA)",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM Reports & \nSeller Reports"
  },
  {
    "id": 38.2,
    "scenario_family": "Listed Derivatives above block size - Investment firm (both EU and Non-EU) filling client order (market order) on RM",
    "scenario_description": "Non-EU Investment Firm negotiates with another EU Investment firm which then reported on an RM and then sells to an underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "ETD",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "MTCH",
    "deferral": "ILQD",
    "enhanced_deferral": "FWAF",
    "post_trade_flags": "N/A",
    "ptt_outcome": "RM reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "RM & XOFF",
    "transaction_product": "ETD",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT\n2nd Report: No MiFID entities to report",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "FWAF",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "RM reports"
  },
  {
    "id": 39.1,
    "scenario_family": "Direct Electronic Access - EU client trading with on RM via DEA",
    "scenario_description": "Underlying client uses an EU Investment firm DEA to an RM to execute a trade, the EU Investment firm then allocates the trade back to the underlying client",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Shares",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "RM",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "LRGS",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ALGO",
    "ptt_outcome": "RM Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "RM",
    "transaction_product": "Shares",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "LRGS",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ALGO",
    "expected_outcome": "RM Reports"
  },
  {
    "id": 40.1,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm allocates the outcome of a trade to underlying clients",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.2,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm novates an existing derivative contract",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "CDS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.3,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm exercise a cash option",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Index Option",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Index Option",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.4,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm exercises a physical option",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Option",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Commodity Option",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.5,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm trade matures",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.6,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment resolves a contractual cancel",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission Allowance Derivative",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Emission Allowance Derivative",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.7,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm conducts a portfolio compression",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS Index",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "CDS Index",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.8,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm conducts a bilateral compression",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "IRS Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.9,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm conducts multi-lateral compression",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Forward",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "FX Forward",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 40.1,
    "scenario_family": "Post-trade lifecycle events",
    "scenario_description": "EU Investment firm executes an inter-entity compression",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "N/A",
    "seller_trading_capacity": "N/A",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "No Reports",
    "execution_time": "10.00 26.10.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "N/A",
    "transaction_seller_trading_capacity": "N/A",
    "venue": "RM",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "No",
    "eligible_for_ptt": "No",
    "eligible_for_nqu_equ": "No",
    "eligibility_reasoning": "Event: Out-of-scope for MiFID II PTT",
    "eligible_for_deferrals": "N/A",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "No Reports"
  },
  {
    "id": 41.1,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "EU Investment Firm executes a trade via voice prior to the Brexit transition and then amends the trade after the Brexit transition",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Option",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm (UK IF Post-Brexit)",
    "seller": "EU Investment Firm",
    "lifecycle_event": "AMND",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC & AMND",
    "ptt_outcome": "Seller Reports",
    "execution_time": "16.55 31.12.20",
    "buyer_type": "EU Investment Firm (UK IF Post-Brexit)",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Commodity Option",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "AMND",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC & AMND",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 41.2,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "EU Investment Firm executes with another EU Investment Firm before transition, after the transition both are UK non-EU IF and the trade is cancelled",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm (UK IF Post-Brexit)",
    "seller": "EU Investment Firm (UK IF Post-Brexit)",
    "lifecycle_event": "CANC",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "CANC",
    "ptt_outcome": "Seller Reports",
    "execution_time": "16.55 31.12.20",
    "buyer_type": "EU Investment Firm (UK IF Post-Brexit)",
    "seller_type": "EU Investment Firm (UK IF Post-Brexit)",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "CANC",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "CANC",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 41.3,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "Post-Brexit an EU Investment firm buys from a UK IF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS Swap",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports (UK APA) & Buyer Reports",
    "execution_time": "10.00 10.2.21",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "IRS Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports (UK APA) & Buyer Reports"
  },
  {
    "id": 41.4,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "EU Investment Firm executes a trade via voice prior to the Brexit transition and benefits from a deferral that requires reporting after the transition",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "FX Option",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "ILQD",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "16.55 31.12.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "FX Option",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "ILQD",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 41.5,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "EU Investment Firm executes a trade via voice prior to the Brexit transition and benefits from an enhanced deferral that requires reporting after the transition",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Swap",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "LMTF",
    "post_trade_flags": "FULF",
    "ptt_outcome": "Seller Reports",
    "execution_time": "16.55 31.12.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Commodity Swap",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "Yes",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "LMTF",
    "report_post_trade_flags": "FULF",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 41.6,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm executes a trade on a UK-based OTF after the transition",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission Allowance",
    "totv": "Yes (UK TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "OTF Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Emission Allowance",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "OTF Reports"
  },
  {
    "id": 41.7,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "EU Investment Firm executes a trade on a UK-based OTF after the transition",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Emission Allowance Derivative",
    "totv": "Yes (UK TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "OTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "OTF Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "OTF",
    "transaction_product": "Emission Allowance Derivative",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "OTF Reports"
  },
  {
    "id": 41.8,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm sells a bond to an EU Investment Firm off-venue that is TOTV in the EU & the UK",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm (UK)",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports (to UK APA) & Buyer Reports",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports (to UK APA) & Buyer Reports"
  },
  {
    "id": 41.9,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm buys a bond from an EU Investment Firm off-venue that is TOTV in the EU & the UK",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (UK TOTV & EU TOTV)",
    "buyer": "Non-EU Investment Firm (UK)",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "Non-EU Investment Firm (UK)",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV & EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 41.1,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm sells a bond to an EU Investment Firm off-venue that is TOTV in the EU",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (EU TOTV)",
    "buyer": "EU Investment Firm",
    "seller": "Non-EU Investment Firm (UK)",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "Non-EU Investment Firm (UK)",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports"
  },
  {
    "id": 41.11,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm buys a bond from an EU Investment Firm off-venue that is TOTV in the UK",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (UK TOTV)",
    "buyer": "Non-EU Investment Firm (UK)",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Buyer Reports (to UK APA)",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "Non-EU Investment Firm (UK)",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "XOFF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (UK TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Buyer Reports (to UK APA)"
  },
  {
    "id": 41.12,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK SI sells a bond to an EU SI off-venue that is TOTV in the EU",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (EU TOTV)",
    "buyer": "SI",
    "seller": "SI (UK)",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports (Buyer) & SI Reports (Seller)",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "SI",
    "seller_type": "SI (UK)",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports (Buyer) & SI Reports (Seller)"
  },
  {
    "id": 41.13,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK SI buys a bond from an EU SI off-venue that is TOTV in the EU and the UK",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (EU TOTV & UK TOTV)",
    "buyer": "SI (UK)",
    "seller": "SI",
    "lifecycle_event": "New",
    "execution_type": "SI",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "SI Reports (Seller)",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "SI (UK)",
    "seller_type": "SI",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "SI",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (EU TOTV & UK TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "SI Reports (Seller)"
  },
  {
    "id": 41.14,
    "scenario_family": "Brexit Scenarios",
    "scenario_description": "UK Investment Firm executes on an EU MTF",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Bond",
    "totv": "Yes (EU TOTV)",
    "buyer": "Non-EU Investment Firm (UK)",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 01.2.20",
    "buyer_type": "Non-EU Investment Firm (UK)",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes (EU TOTV)",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm & 1 UK equivalent firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 42.1,
    "scenario_family": "Assisted Reporting",
    "scenario_description": "A BBG client that has established an assisted reporting relationship with another BBG client executes a trade off-venue and sells the product",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "Off-venue",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 42.2,
    "scenario_family": "Assisted Reporting",
    "scenario_description": "A BBG client that has established an assisted reporting relationship with another BBG client executes a trade off-venue and buys the product",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "Off-venue",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 42.3,
    "scenario_family": "Assisted Reporting",
    "scenario_description": "A BBG client that has established an assisted reporting relationship with another BBG client executes a trade on venue",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Convertible Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "MTF",
    "submitting_entity_trading_capacity": "DEAL",
    "buyer_trading_capacity": "DEAL",
    "seller_trading_capacity": "DEAL",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "MTF Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "DEAL",
    "transaction_buyer_trading_capacity": "DEAL",
    "transaction_seller_trading_capacity": "DEAL",
    "venue": "MTF",
    "transaction_product": "Convertible Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "MTF Reports"
  },
  {
    "id": 43.1,
    "scenario_family": "Agency Cross",
    "scenario_description": "An EU Investment firm interposes between two underlying EU-IF clients in an Agency capacity. The trades are executed for the same volume and price",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Corporate Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ACTX",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "Off-venue",
    "transaction_product": "Corporate Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ACTX",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 43.2,
    "scenario_family": "Agency Cross",
    "scenario_description": "An EU Investment firm interposes between two underlying EU-IF clients in an Agency capacity. The trades are executed at the same time, for the same volume and price",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Sovereign Bond",
    "totv": "Yes",
    "buyer": "EU Investment Firm",
    "seller": "EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ACTX",
    "ptt_outcome": "Seller Reports",
    "execution_time": "10.00 10.2.21",
    "buyer_type": "EU Investment Firm",
    "seller_type": "EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "Off-venue",
    "transaction_product": "Sovereign Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: MiFID Investment Firms\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ACTX",
    "expected_outcome": "Seller Reports"
  },
  {
    "id": 43.3,
    "scenario_family": "Agency Cross",
    "scenario_description": "An EU Investment firm interposes between two underlying non-IF clients in an Agency capacity. The trades are executed for the same volume and price",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Convertible Bond",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ACTX",
    "ptt_outcome": "Interposing EU Firm Reports",
    "execution_time": "10.00 10.2.22",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "Off-venue",
    "transaction_product": "Convertible Bond",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ACTX",
    "expected_outcome": "Interposing EU Firm Reports"
  },
  {
    "id": 43.4,
    "scenario_family": "Agency Cross",
    "scenario_description": "A management company transfers from one UCIT to another using an investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "CDS",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "AOTC",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ACTX",
    "ptt_outcome": "Interposing EU Firm Reports",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "AOTC",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "Off-venue",
    "transaction_product": "CDS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ACTX",
    "expected_outcome": "Interposing EU Firm Reports"
  },
  {
    "id": 43.5,
    "scenario_family": "Agency Cross",
    "scenario_description": "A management company transfers from one UCIT to another using an investment firm",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "IRS",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "MTCH",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "ACTX",
    "ptt_outcome": "Interposing EU firm Report",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "MTCH",
    "venue": "Off-venue",
    "transaction_product": "IRS",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "ACTX",
    "expected_outcome": "Interposing EU firm Report"
  },
  {
    "id": 43.6,
    "scenario_family": "Agency Cross",
    "scenario_description": "A management company (operating as an Investment Firm) transfers from one UCIT to another",
    "firm_type": "MIFID II Investment Firm (Includes SI)",
    "product": "Commodity Future",
    "totv": "Yes",
    "buyer": "Non-EU Investment Firm",
    "seller": "Non-EU Investment Firm",
    "lifecycle_event": "New",
    "execution_type": "Off-venue",
    "submitting_entity_trading_capacity": "MTCH",
    "buyer_trading_capacity": "AOTC",
    "seller_trading_capacity": "AOTC",
    "deferral": "N/A",
    "enhanced_deferral": "Not in scope",
    "post_trade_flags": "N/A",
    "ptt_outcome": "Interposing EU firm Report",
    "execution_time": "10.00 10.2.20",
    "buyer_type": "Non-EU Investment Firm",
    "seller_type": "Non-EU Investment Firm",
    "submitting_trading_capacity": "MTCH",
    "transaction_buyer_trading_capacity": "AOTC",
    "transaction_seller_trading_capacity": "AOTC",
    "venue": "Off-venue",
    "transaction_product": "Commodity Future",
    "part_of_package": "No",
    "ppt_workflow_lifecycle": "New",
    "equity_nonequity": "Non-Equity",
    "instrument_inscope": "Yes",
    "instrument_totv": "Yes",
    "ptt_reportable_lifecycle_event": "Yes",
    "eligible_for_ptt": "Yes",
    "eligible_for_nqu_equ": "Yes",
    "eligibility_reasoning": "Instrument: In-scope & TOTV\nEntities: 1 MiFID Investment Firm\nEvent: In-scope for PTT",
    "eligible_for_deferrals": "No",
    "deferral_reasoning": "N/A",
    "enhanced_deferral_reasoning": "Not in scope",
    "report_post_trade_flags": "N/A",
    "expected_outcome": "Interposing EU firm Report"
  }
]
